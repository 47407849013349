/**
 *
 * @param {*} baseURI - The origins site host (ex: roimotors.com)
 * @param {*} targetHost - The targets href host domain (ex: google.com)
 * @param {*} href - the full href included in the element
 * @returns bool; true if external, false if internal
 */

const isExternalUrl = (baseURI, targetHost, href) => {
	let isExternal;

	if ([baseURI, targetHost, href].every((el) => typeof el !== 'undefined')) {
		isExternal =
			baseURI && (!baseURI.includes(targetHost) || href.includes('tel:'));
	}

	return isExternal;
};

export default isExternalUrl;
