import findCustomTrackingData from './findCustomTrackingData';

const dataAttributes = {
	id: ['id', 'promoId'],
	trackingLabel: ['trackingLabel', 'promoTrackingLabel']
};

// getCommonData will return an object with id, trackingLabel,
// For more info go to https://coxautoinc.sharepoint.com/sites/teams-BurlingtonCodeFactory/SitePages/DDC%20Tracking%20Standardization.aspx
const getCommonData = (element) => {
	const properties = {};

	Object.entries(dataAttributes).forEach(([commonName, attributes]) => {
		attributes.forEach((attribute) => {
			Object.assign(
				properties,
				element?.dataset?.[attribute] && {
					[commonName]: element.dataset[attribute]
				}
			);
		});
	});

	const customTrackingData = findCustomTrackingData(element) || {};

	return Object.assign(properties, customTrackingData);
};

export default getCommonData;
