import {
	getTrackedAttributes,
	getTrackedAttributesString,
	getTrackedInfo
} from './trackingAttributesGenerator';

export default ({ locale }) => {
	window.DDC.getTrackedAttributes = () => getTrackedAttributes();

	window.DDC.getTrackedAttributesString = () => getTrackedAttributesString();

	window.DDC.getTrackedInfo = (type) => getTrackedInfo(locale, type);
};
