/**
 *
 * @param {Object} win
 * @param {String} prefParams
 * @returns
 */
const getParamsForExtraInfo = (win, prefParams) => {
	const params = [];

	if (prefParams) {
		params.push(prefParams);
	}

	return params.length ? params.join(',').split(',') : [];
};

export default getParamsForExtraInfo;
