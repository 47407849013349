export default (key, value, expirationDays = false, path = '/') => {
	let expirationStr = '';
	/*
		If they explicity set the expiration days to 0,
			they intentionally did this to remove the cookie therefore lets create the expiration
		But if the expiration is null, they probably meant to put false so lets skip the expiration
		And if they put undefined they intentionally wanted to use the default value (which is false),
			and false will be used and the expiration will not be created
	*/
	if (expirationDays || expirationDays === 0) {
		const d = new Date();
		d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
		expirationStr = `expires=${d.toUTCString()};`;
	}
	document.cookie = `${key}=${value};${expirationStr}path=${path}`;
};
