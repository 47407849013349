import getCookie from './getCookie';
import setCookie from './setCookie';
import removeCookie from './removeCookie';
import { TrackedInfo } from '../global-constants';

// TRACKING_COOKIES manages session parameters and their persistant cookies.
const TRACKING_COOKIES = new Set();
TRACKING_COOKIES.add(TrackedInfo.TCD_KEYWORD_ID);
TRACKING_COOKIES.add(TrackedInfo.TCD_CAMPAIGN_ID);
TRACKING_COOKIES.add(TrackedInfo.TCD_AD_ID);
TRACKING_COOKIES.add(TrackedInfo.DDC_REF);
TRACKING_COOKIES.add(TrackedInfo.GC_LID);
TRACKING_COOKIES.add(TrackedInfo.T3_WEBSITE);
TRACKING_COOKIES.add(TrackedInfo.ADP_FOUREYES);

// TRACKED_INFO_COOKIES lists 3rd party cookies included in TrackedInfo.
const TRACKED_INFO_COOKIES = new Set();
TRACKED_INFO_COOKIES.add(TrackedInfo.CLLC_MKT_CODES);
TRACKED_INFO_COOKIES.add(TrackedInfo.PERMANENT_COOKIE_ID);
TRACKED_INFO_COOKIES.add(TrackedInfo.UNIVERSAL_USER_ID);
TRACKED_INFO_COOKIES.add(TrackedInfo.USER_PROFILE_ID);
TRACKED_INFO_COOKIES.add(TrackedInfo.T3_WEBSITE);
TRACKED_INFO_COOKIES.add(TrackedInfo.PIXALL_ID);
TRACKED_INFO_COOKIES.add(TrackedInfo.VINLENS_SESSION_ID);

// TRACKED_PARAMETERS manages session parameters that are mersisted into local storage.
const TRACKED_PARAMETERS = new Set([...TRACKING_COOKIES]);
TRACKED_PARAMETERS.add(TrackedInfo.CLLC_BID);
TRACKED_PARAMETERS.add(TrackedInfo.CLLC_PID);
TRACKED_PARAMETERS.add(TrackedInfo.CLLC_ADID);
TRACKED_PARAMETERS.add(TrackedInfo.CLLC_RID);

// Currently used for tracking FB promotions leads for FD.
TRACKED_PARAMETERS.add(TrackedInfo.ALT_SOURCE);
// Used to allows for more detailed tracking of leads submitted from custom forms
TRACKED_PARAMETERS.add(TrackedInfo.SECONDARY_LEAD_SOURCE);
TRACKED_PARAMETERS.add(TrackedInfo.FMCCMP);

// Used for tracking Lexus promotions.
TRACKED_PARAMETERS.add(TrackedInfo.CID);
TRACKED_PARAMETERS.add(TrackedInfo.PDSRCH);

// Used for tracking Volvo promotions
TRACKED_PARAMETERS.add(TrackedInfo.REF_ID);

// Used for tracking AOA leads
TRACKED_PARAMETERS.add(TrackedInfo.ADOBE_CLOUD_ID);

// Used for tracking honda leads
TRACKED_PARAMETERS.add(TrackedInfo.UTM_CAMPAIGN);
TRACKED_PARAMETERS.add(TrackedInfo.UTM_MEDIUM);
TRACKED_PARAMETERS.add(TrackedInfo.UTM_SOURCE);
TRACKED_PARAMETERS.add(TrackedInfo.ADS_ADGROUP);
TRACKED_PARAMETERS.add(TrackedInfo.ADS_ADID);
TRACKED_PARAMETERS.add(TrackedInfo.ADS_DIGADPROVIDER);

// For Bank of America
TRACKED_PARAMETERS.add(TrackedInfo.MARKETING_CODE);
TRACKED_PARAMETERS.add(TrackedInfo.ZIP);

// TRACKED_ATTRIBUTES is the list of all local storage attributes included in TrackedInfo.
const TRACKED_ATTRIBUTES = new Set([...TRACKED_PARAMETERS]);
TRACKED_ATTRIBUTES.add(TrackedInfo.SESSION_REFERRER);
TRACKED_ATTRIBUTES.add(TrackedInfo.SESSION_REFERRER_DOMAIN);
TRACKED_ATTRIBUTES.add(TrackedInfo.CAMPAIGN_PARAMS);
TRACKED_ATTRIBUTES.add(TrackedInfo.CONTACT_ID);
TRACKED_ATTRIBUTES.add(TrackedInfo.REACHLOCAL_COOKIE);
TRACKED_ATTRIBUTES.add(TrackedInfo.SEM_ID);
TRACKED_ATTRIBUTES.add(TrackedInfo.REGION_ID);
TRACKED_ATTRIBUTES.add(TrackedInfo.KEYWORD_ID);

const TRACKED_ATTRS_NAME = 'ddcstdata';
const V9_TYPE = 'v9';

const setSessionItem = (key, value) => {
	if (!value) {
		return;
	}
	sessionStorage?.setItem(key, value);
};

const getSessionItem = (key) => {
	const validKey = key && key !== '';
	if (validKey) {
		return sessionStorage?.getItem(key);
	} else {
		return null;
	}
};

const funcSafe = (func, arg) => {
	if (arg) {
		try {
			return func(arg);
		} catch (e) {
			return '';
		}
	}
	return '';
};

const parseTrackedAttributeParameter = (urlParams) => {
	const trackedData = urlParams[TRACKED_ATTRS_NAME];
	if (!trackedData) {
		return;
	}

	const attributes = funcSafe(atob, trackedData)
		.split('&')
		.map((data) => {
			const dataArr = data.split('=');
			return {
				key: dataArr[0],
				value: dataArr[1]
			};
		});

	attributes.forEach(({ key, value }) => {
		if (TRACKED_ATTRIBUTES.has(key)) {
			const trackValue = value ? decodeURI(value) : null;
			sessionStorage?.setItem(key, trackValue);
		} else if (TRACKED_INFO_COOKIES.has(key)) {
			const trackValue = value ? decodeURI(value) : null;
			setCookie(key, trackValue);
		}
	});
};

const verifyRequestParams = (trackingNames, urlParams) => {
	// eslint-disable-next-line no-restricted-syntax
	for (const trackName of trackingNames) {
		const trackValue = urlParams[trackName];
		if (trackValue) {
			return true;
		}
	}
	return false;
};

const setTrackedDataFromParams = (trackingNames, urlParams) => {
	trackingNames.forEach((trackName) => {
		const trackValue = urlParams[trackName];
		// remove session if no tracValue found
		if (!trackValue) {
			sessionStorage?.removeItem(trackName);
			// Temp compat while the back-end still needs cookies
			removeCookie(trackName);
		} else {
			setSessionItem(trackName, trackValue);
			// Temp compat while the back-end still needs cookies
			setCookie(trackName, trackValue, 30);
		}
	});
};

const verifySessionAttrs = (trackingNames) => {
	// eslint-disable-next-line no-restricted-syntax
	for (const trackName of trackingNames) {
		const trackValue = getSessionItem(trackName);
		if (trackValue) {
			return true;
		}
	}
	return false;
};

const setTrackedDataFromCookies = (trackingNames) => {
	trackingNames.forEach((trackName) => {
		const trackValue = getCookie(trackName);
		setSessionItem(trackName, trackValue);
	});
};

const processTrackedData = (trackingNames, urlParams) => {
	if (verifyRequestParams(trackingNames, urlParams)) {
		setTrackedDataFromParams(trackingNames, urlParams);
	}

	if (!verifySessionAttrs(trackingNames)) {
		setTrackedDataFromCookies(trackingNames);
	}
};

/**
 *
 * @param {FormData} formData
 * @returns
 */
const getTrackedAttributes = (formData) => {
	const attributes = new Map();

	attributes.set(TrackedInfo.REFERRER, document?.location?.href);

	TRACKED_ATTRIBUTES.forEach((key) => {
		const attrValue = getSessionItem(key) || formData?.get?.(key);
		if (attrValue) {
			attributes.set(key, attrValue);
		}
	});

	TRACKED_INFO_COOKIES.forEach((key) => {
		const cookieValue = getCookie(key);
		if (cookieValue) {
			attributes.set(key, cookieValue);
		}
	});

	if (!attributes.has(TrackedInfo.CONTACT_ID)) {
		const contactIdCookieValue = getCookie(TrackedInfo.CONTACT_ID);
		if (contactIdCookieValue) {
			attributes.set(TrackedInfo.CONTACT_ID, contactIdCookieValue);
		}
	}

	return attributes;
};

/**
 *
 * @param {FormData} formData
 * @returns
 */
const getTrackedAttributesString = (formData) => {
	const attributes = getTrackedAttributes(formData);

	return funcSafe(
		btoa,
		Array.from(attributes.entries())
			.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
			.join('&')
	);
};

const updateSession = (urlParams) => {
	parseTrackedAttributeParameter(urlParams);
	processTrackedData(TRACKING_COOKIES, urlParams);

	// Update session for TRACKED_PARAMETERS
	TRACKED_PARAMETERS.forEach((key) => {
		const attrValue = urlParams[key];
		setSessionItem(key, attrValue);
	});

	const tcParam = urlParams.tc;
	setSessionItem(TrackedInfo.SEM_ID, tcParam);

	const kwParam = urlParams.kw;
	if (kwParam) {
		const kwParamSplit = kwParam.split(':');
		if (kwParamSplit.length > 1) {
			setSessionItem(TrackedInfo.REGION_ID, kwParamSplit[1]);
		}
		setSessionItem(TrackedInfo.KEYWORD_ID, kwParamSplit[0]);
	}

	const fromParam = urlParams.from;
	if (fromParam === 'AcuraInventory') {
		setSessionItem(TrackedInfo.DDC_REF, fromParam);
	}

	const semCookie = getCookie(TrackedInfo.SEM_ID);
	if (semCookie && semCookie.includes('tc=')) {
		const start = semCookie.indexOf('tc=') + 3;
		const end = semCookie.indexOf('&', start);
		let dominatorid = null;
		if (end < 0) {
			dominatorid = semCookie.substring(start);
		} else {
			dominatorid = semCookie.substring(start, end);
		}
		setSessionItem(TrackedInfo.SEM_ID, dominatorid);
		setSessionItem(TrackedInfo.REACHLOCAL_COOKIE, semCookie);
	}

	// TODO Remove once all front-end consumers have migrated to
	// window.DDC.getTrackedAttributesString()
	setSessionItem(TRACKED_ATTRS_NAME, getTrackedAttributesString());

	window.dispatchEvent(new Event('ws-tracking-get-tracked-info-ready'));
};

// TODO: This probably doesn't belong here. The only consumer appears to be
// v9/viewmodel/components/digital_retailing/cox
const getTrackedInfo = (locale = 'en_US', type = null) => {
	const attributes = getTrackedAttributes();
	attributes.set(TrackedInfo.TYPE, `${type}`);

	attributes.set(
		TrackedInfo.USER_SESSION_ID,
		getSessionItem(TrackedInfo.USER_SESSION_ID)
	);
	attributes.set(TrackedInfo.USER_AGENT, navigator?.userAgent);
	// TODO: Should move get REMOTE_HOST based on akamai

	// TODO: Considering to set/get LOCALE from Akamai
	attributes.set(TrackedInfo.LOCALE, locale);

	// TODO: Find the way to get other site property: PORTAL_ID
	const { siteSettings, dataLayer } = window?.DDC;
	if (siteSettings) {
		attributes.set(TrackedInfo.SITE_ID, siteSettings?.siteId);
		attributes.set(TrackedInfo.PLATFORM, V9_TYPE);
		attributes.set(TrackedInfo.SKIN, dataLayer?.site?.design?.themekit);
		attributes.set(
			TrackedInfo.VERSION,
			dataLayer?.site?.design?.variationId
		);
		// TODO: condition to getHomepage then set TEMPLATE_EXTRA attribute
	}

	// TODO: consider add logic for Variant variant

	const trackInfo = {};
	trackInfo.timestamp = new Date();
	trackInfo.attributes = attributes;
	return trackInfo;
};

export {
	updateSession,
	getTrackedAttributes,
	getTrackedAttributesString,
	getTrackedInfo
};
