/**
 * Find the title from the closest .ui-dialog, .ddc-mobile-slidein, or
 * the .ui-dialog classed element with the attribute of ws-tracking-dialog-latest="true"
 * @param {HTMLElement} element
 * @returns The title of the dialog from element or the one in the document with the correct attribute.
 */
const findDialogTitle = (dialog) => {
	const dialogTitleEl =
		dialog?.querySelector?.('.ui-dialog-title') ||
		dialog?.querySelector?.('.ddc-heading-2') ||
		dialog?.querySelector?.('.ddc-mobile-slidein-content-inner h3');

	return dialogTitleEl?.textContent?.trim?.() || '';
};

export default findDialogTitle;
