const getWidgetHeading = (htmlElement = {}) => {
	let element = htmlElement;
	if (htmlElement instanceof jQuery) {
		element = htmlElement.get(0);
	}

	return element
		?.closest?.('[data-widget-id]')
		?.querySelector('.widget-heading')
		?.textContent?.trim();
};

export default getWidgetHeading;
