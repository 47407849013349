import { SET_DL_ITEMS } from '../actions/types';

export default function dlItems(state = [], action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_DL_ITEMS:
				return action.payload;

			// no default
		}
	}

	return state;
}
