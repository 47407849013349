const findCustomTrackingData = (element) => {
	let matchResult = false;
	const closestElement = element?.closest?.('[class*="tracking-"]');

	if (closestElement) {
		const classList = closestElement?.classList?.value;
		const classes = classList.match(/tracking-(\w+)-(\w+)/g) || [];
		const classObj = {};

		classes.forEach((classStr) => {
			const [_, key, value] = classStr.split('-');
			classObj[key] = value;
		});

		if (Object.keys(classObj).length) {
			matchResult = classObj;
		}
	}

	return matchResult;
};

export default findCustomTrackingData;
