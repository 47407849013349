/**
 * Returns an object containing the attributes of an HTML element.
 * @param {HTMLElement|jQuery} htmlElement - The HTML element to get attributes
 * from.
 * @returns {Object} An object containing the attributes of the HTML element.
 * Each attribute is a property of the object with the attribute name as the key
 * and the attribute value as the value.
 *
 * There are some properties that can be considered attributes that normally will not make it on the attribute property
 * These are manually added on a per case basis
 */
const getElementAttributes = (htmlElement = {}) => {
	let element = htmlElement;
	if (htmlElement instanceof jQuery) {
		element = htmlElement.get(0);
	}

	const attributeList = element?.attributes || {};

	const attributeObject = Array.from(attributeList).reduce(
		(obj, attribute) => ({
			...obj,
			[attribute.name]: attribute.value
		}),
		{}
	);

	if (element?.tagName) {
		attributeObject.tagName = element.tagName;
	}

	if (element?.innerText) {
		attributeObject.innerText = element.innerText;
	}
	if (element?.type) {
		attributeObject.type = element.type;
	}

	if (element?.href) {
		attributeObject.rawHref = element.href; // this one includes the baseUrl
	}

	return attributeObject;
};

export default getElementAttributes;

/*
// Example usage:
const anchorElement = $('a.btn');
const attributeObject = getElementAttributes(anchorElement);
console.log(attributeObject);
*/
