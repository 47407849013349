export default () => {
	const timestamp = Date.now();
	const clickPathFromSessionStorage = sessionStorage.getItem(
		'ws-tracking-clickpath'
	);
	let clickPath;

	// this check is temporary while we roll this out from being stored as an object
	if (clickPathFromSessionStorage?.startsWith('{')) {
		const clickPathObject = JSON.parse(clickPathFromSessionStorage);
		clickPath = Object.values(clickPathObject).map((value) => value);
	} else {
		clickPath = JSON.parse(
			sessionStorage.getItem('ws-tracking-clickpath') || '[]'
		);
	}

	const uuid = window.DDC.dataLayer.vehicles[0]?.uuid;
	const { isVdp } = window.DDC.dataLayer.page.pageInfo;
	clickPath.push({
		path: window.location.href,
		viewType: 'page',
		timestamp
	});
	if (isVdp && uuid) {
		clickPath.push({
			path: uuid,
			viewType: 'vdp',
			timestamp
		});
	}
	sessionStorage.setItem('ws-tracking-clickpath', JSON.stringify(clickPath));
};
