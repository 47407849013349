export default (name) => {
	const cookies = document.cookie.split(';');
	let cookieValue = null;

	cookies.forEach((cookie) => {
		const [cookieName, value] = cookie.split('=');
		if (cookieName.trim() === name) {
			cookieValue = value;
		}
	});

	return cookieValue;
};
