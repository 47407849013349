// I didn't include SELECT_MAKE selector, which has further logic,
// nor the arroe selector which are on their own map
const widgetSectionSelectors = new Map([
	['datepicker', '#ui-datepicker-div'],
	['Vehicle Details Sidebar', '.vdp-sidebar'],
	['Carousel', '[data-widget-name="ws-vehicle-media"]'],
	['Incentives', '[data-widget-name="incentives-search-detail"]'],
	['Footer', '.ddc-footer'],
	['Global Nav', '.page-header, .navbar, .mycars-default'],
	['More Info', '[data-widget-name="ws-content-cta"]'],
	[
		'amt',
		'.alias-sitebuilder-gm-protection-plans-1 [data-widget-id="content5"]'
	],
	[
		'inventory-listing1',
		'.inventory-listing-default [data-widget-id="inventory-listing1"]'
	]
]);

const getSection = (element, DDC) => {
	let returnValue;
	if (element instanceof Element) {
		// eslint-disable-next-line no-restricted-syntax
		for (const [sectionName, selector] of widgetSectionSelectors) {
			if (element.closest(selector) !== null) {
				return sectionName;
			}
		}

		let widget = element.closest('[data-widget-name]');
		// could be vanilla javascript (if widget.dataset is truthy) or jquery (if widget.dataset is falsy)
		if (widget) {
			// If widget is not jQuery return widget else return first element of jQuery object
			widget = widget instanceof Node ? widget : widget[0];

			const { widgetName } = widget.dataset;
			const pageContainer = DDC?.client?.isMobile
				? '.ddc-page'
				: '.ddc-wrapper';
			const dialogEl = element.closest('.ui-dialog');

			if (
				(dialogEl !== null || widget.closest(pageContainer) !== null) &&
				widgetName
			) {
				// If event triggering element was in a dialog, first look for dialog title and use that if we can
				if (dialogEl !== null) {
					const dialogTitleEl =
						dialogEl.querySelector('.ui-dialog-title');
					if (dialogTitleEl) {
						return dialogTitleEl.innerText;
					}
				}

				// then fall back to widget heading of the widget that the event triggering element is in
				const widgetHeadingEl = widget.querySelector('.widget-heading');
				const widgetHeadingText =
					widgetHeadingEl && widgetHeadingEl.innerText;

				if (widgetName === 'promotions-listing') {
					// when event triggering element is a promo pagination dot, we need to append "scroller dot"
					if (element.closest('.slick-dots') !== null) {
						return `${widgetHeadingText}:scroller dot`;
					}
				}

				if (widgetHeadingText) {
					return widgetHeadingText;
				}
			}

			// fall back to widgetId if no dialog title or widgetHeading
			return widget.dataset && widget.dataset.widgetId;
		} else {
			returnValue = element.className;
		}
	}
	return returnValue;
};

export default getSection;
