// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.
export const TrackedInfo = {
	USER_SESSION_ID: 'userSessionId',
	CONTACT_ID: 'contactId',
	SITE_ID: 'siteId',
	PORTAL_ID: 'portalId',
	SEM_ID: 'RlocalUID',
	PERMANENT_COOKIE_ID: 'permanentCookieId',
	SESSION_REFERRER: 'sessionReferrer',
	SESSION_REFERRER_DOMAIN: 'sessionReferrerDomain',
	REFERRER: 'referrer',
	TYPE: '0vt',
	EXTRA: '0ve',
	USER_AGENT: 'userAgent',
	REMOTE_HOST: 'remoteHost',
	LOCALE: 'locale',
	REACHLOCAL_COOKIE: 'reachlocalCookie',
	KEYWORD_ID: 'keywordId',
	REGION_ID: 'regionId',
	TCD_KEYWORD_ID: 'tcdkwid',
	TCD_AD_ID: 'tcdadid',
	TCD_CAMPAIGN_ID: 'tcdcmpid',
	PLATFORM: 'platform',
	VERSION: 'version',
	SKIN: 'skin',
	TEMPLATE_EXTRA: 'templateExtra',
	USER_PROFILE_ID: 'userProfileId',
	UNIVERSAL_USER_ID: 'userId',
	CAMPAIGN_PARAMS: 'campaignparams',
	PIXALL_ID: 'ddc_abc_cache',

	CLLC_BID: 'bid',
	CLLC_PID: 'pid',
	CLLC_ADID: 'adid',
	CLLC_RID: 'rid',
	CLLC_MKT_CODES: 'mktcodes',

	FMCCMP: 'fmccmp',
	ALT_SOURCE: 'AltLeadSource',
	SECONDARY_LEAD_SOURCE: 'SecondaryLeadSource',
	T3_WEBSITE: 't3website',
	VINLENS_SESSION_ID: 'lmc',

	CID: 'cid',
	PDSRCH: 'pdsrch',

	REF_ID: 'refId',

	DDC_REF: 'ddcref',
	GC_LID: 'gclid',

	ADOBE_CLOUD_ID: 'adobeCloudId',

	UTM_CAMPAIGN: 'utm_campaign',
	UTM_MEDIUM: 'utm_medium',
	UTM_SOURCE: 'utm_source',
	ADS_ADGROUP: 'ads_adgroup',
	ADS_ADID: 'ads_adid',
	ADS_DIGADPROVIDER: 'ads_digadprovider',

	ADP_FOUREYES: 'adpFoureyes',

	MARKETING_CODE: 'mktgCode',
	ZIP: 'zip'
};
